// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDNpfxV2Ud5W7syciVV6xw-lNltmSLkcg8",
  authDomain: "bansi-app.firebaseapp.com",
  projectId: "bansi-app",
  storageBucket: "bansi-app.appspot.com",
  messagingSenderId: "266468159798",
  appId: "1:266468159798:web:ee1466596a139316608397"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);