import styles from "./Contact.module.css";

export const Contact = () => {
    return (
        <section className={styles.container} id="contact">
            <h1 className={styles.title}>Contact Us</h1>
            <div className={styles.wrapper}>
            <div className={styles.content}>
                <div className={styles.phone}>
                    <img src={require("../../assets/phone.png")} alt="phone icon" />
                    <a href="tel:+17325273555"> (732) 527-3555 </a>
                </div>
                <div className={styles.email}>
                    <img src={require("../../assets/email.png")} alt="phone icon" />
                    <p> <a href="mailto:bansisalon20@gmail.com"> bansisalon20@gmail.com</a> </p>
                </div>
                <div className={styles.address}>
                    <img src={require("../../assets/address.png")} alt="address icon" />
                    <p className={styles.address}> 849 Green St Unit 3, Iselin, NJ 08830 </p>
                </div>
                <h3 className={styles.subTitle}>Salon Hours</h3>
                <div className={styles.hours}>
                    <p><b>Tue - Sat</b>: 10:00 AM - 7:30 PM </p>
                    <p><b>Sun</b>: 10:00 AM - 6:00 PM</p>
                    <p><b>Mon</b>: Closed</p>
                </div>
                <b className={styles.text}>Walk-ins are welcome.</b>
                
            </div>
            <div className={styles.map}>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d48481.87823261193!2d-74.35683773117!3d40.58316418152222!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c3b554559a0121%3A0x55a704f2f28f9c4b!2sBansi%20Salon%20%26%20Spa!5e0!3m2!1sen!2sus!4v1710861539451!5m2!1sen!2sus" ></iframe>
            </div>
            </div>
            <div className={styles.upBtn}>
                <a href="#root" ><img src={require("../../assets//upBtn/upBtn-2.png")} alt="up icon" /></a>
            </div>
        </section>
    )
};