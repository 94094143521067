import styles from './App.module.css';
import { Appointment } from './components/Appointment/Appointment';
import { Contact } from './components/Contact/Contact';
import { Navbar } from "./components/Navbar/Navbar";
import { Reviews } from './components/Reviews/Reviews';
import { Services } from './components/Services/Services';
import { Welcome } from './components/Welcome/Welcome';


function App() {
  return (
    <div className={styles.App}>
      <Navbar />
      <Welcome />
      <Services />
      <Reviews />
      <Appointment />
      <Contact />
    </div>
  );
}

export default App;
