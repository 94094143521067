import { useState } from "react";
import styles from './Navbar.module.css';

export const Navbar = () => {

  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <nav className={styles.navbar}>
      <a className={styles.title} href="/">
        Bansi
      </a>
      <div className={styles.menu}>
        <img className={styles.menuBtn} src={ menuOpen ? require("../../assets/closeNav.png") : require("../../assets/openNav.png")} alt="menu-btn"
          onClick={() => setMenuOpen(!menuOpen)} />
        <ul className={`${styles.menuItems} ${menuOpen && styles.menuOpen}`}
          onClick={() => setMenuOpen(false)}>
          <li>
            <a href="#services">Services</a>
          </li>
          <li>
            <a href="#reviews">Reviews</a>
          </li>
          <li>
            <a href="#appointment">Appointment</a>
          </li>
          <li>
            <a href="#contact">Contact</a>
          </li>
        </ul>
      </div>
    </nav>
  );
}
