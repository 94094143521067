import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faStar} from "@fortawesome/free-solid-svg-icons";
import {faStar as farStar} from "@fortawesome/free-regular-svg-icons";
import styles from "./Reviews.module.css";
import CustomerReviews from "./Reviews-data.json";

export const Reviews = () => {

  return (
      <section className={styles.container} id="reviews">
          <div className={styles.content}>
            <h1 className={styles.title}>What Our Customers Say</h1>
            <ul className={styles.reviewList}>
                {CustomerReviews.map(review => (
                    <li key={review.id} className={styles.reviewItem}>
                        <div>
                            {CustomerReviews.map((_, index) => (
                                <FontAwesomeIcon key={index} icon={index < review.stars ? faStar : farStar} />
                            ))}
                        </div>
                        <p className={styles.reviewComment}>"{review.comment}"</p>
                        <p className={styles.reviewAuthor}>- {review.name}</p>
                    </li>    
                ))}
            </ul>
            <div className={styles.topBlur}/>
            <div className={styles.bottomBlur}/>
          </div>
      </section>
  );
};
