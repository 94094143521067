import { useForm } from 'react-hook-form';
import { useEffect, useState} from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { db } from '../../config/firebase';
import { addDoc, collection, query, getDocs, where } from 'firebase/firestore';
import styles from "./Appointment.module.css";
import emailjs from "emailjs-com";
import DatePicker from 'react-datepicker';


export const Appointment = () => {
    
    const [selectedDate, setSelectedDate] = useState('');

    const minTime = new Date();
    minTime.setHours(10, 0, 0);
    
    const maxTime = new Date();
    if(selectedDate ? selectedDate.getDay() === 0 : maxTime.getDay() === 0 ){
        maxTime.setHours(18, 0, 0);
    } else {
        maxTime.setHours(19, 30, 0);
    }

    const [bookedTimes, setBookedTimes] = useState([]);

    useEffect(() => {
        const fetchBookedTimes = async () => {
            try{
                if(selectedDate){
                    console.log("Date: ", selectedDate);
                    const querySnapshot = await getDocs(
                        query(collection(db, 'appointments'), where ("date", ">=", selectedDate))
                        
                    );
                    
                    const bookedTimesArray = querySnapshot.docs.map(doc => doc.data().date.toDate().getTime())
                        .filter(time => {
                            const appointmentDate = new Date(time);
                            const isSameDate =
                                appointmentDate.getFullYear() === selectedDate.getFullYear() &&
                                appointmentDate.getMonth() === selectedDate.getMonth() &&
                                appointmentDate.getDate() === selectedDate.getDate();
                            /*console.log("Appointment Date: ", appointmentDate);
                            console.log("Selected Date: ", selectedDate)
                            console.log("Is Same Date? ", isSameDate);*/
                            return isSameDate;
                            
                    });
                        
                    
                    setBookedTimes(bookedTimesArray);
                    /*console.log("Booked Times: ", bookedTimes.map(time =>  new Date(time))); */
                }
            } catch (error){
                console.error("Error fetching booked dates: ", error);
            }
        };
        fetchBookedTimes();
    }, [selectedDate]);

    const schema = yup.object().shape({
        name: yup.string().required("*Name missing*"),
        email: yup.string().email().required("*Email missing*"),
        phone: yup.string().required("*Phone number missing*"),
        service: yup.string().required("*Service missing*"),
    });
    /*console.log('Schema:', schema);*/

    const { register, handleSubmit, formState: { errors }} = useForm({
        resolver: yupResolver(schema),
    });
    /*console.log('Register:', register);
    console.log('handleSubmit:', handleSubmit);*/

    const onSubmit = async(data) =>{
        try {
            
            if (selectedDate) {
               
                    const querySnapshot = await getDocs(
                        query(collection(db, 'appointments'), where('date', '>=', selectedDate), where('date', '<=', selectedDate))
                    );
                
                    if (querySnapshot.empty){
                    const docRef = await addDoc(collection(db, 'appointments'), {
                        name: data.name,
                        email: data.email,
                        phone: data.phone,
                        service: data.service,
                        date: selectedDate,
                    })
                    
                        const selectedDateObj = new Date(selectedDate);
                        const formattedDate = selectedDateObj.toLocaleString();
                        
                        const templateParams = {
                        to_email: data.email,
                        to_name: data.name,
                        appointment_date: formattedDate,
                        name: data.name,
                        email: data.email,
                        phone: data.phone,
                        service: data.service,
                        }
                        const response = await emailjs.send('service_n6asi2h', 'template_2q7wrfp', templateParams, 'w8TWX60Z4bZ2aAnn_'
                        );
                        /*console.log('EmailJS Response: ', response);*/
                        alert("Appointment Booked!");
                        /*console.log("Document written with ID: ", docRef.id);*/
                    
                    } else {
                        alert("Appointment duplicate! Pick another time.");
                    };
                
                    
                
            }
        } catch (error) {
            console.error("Error adding document: ", error);
        }
        
    };

    
    return (
        <section className={styles.container} id='appointment'>
            <form onSubmit={handleSubmit(onSubmit)} className={styles.appointmentForm}>
                <h1 className={styles.title}>Book Appointment</h1>
                <input
                    type="text"
                    placeholder="Name"
                    {...register("name")}
                />
                {errors.name && <span className={styles.errorMessage}>{errors.name?.message}</span>}
                <input
                    type="text"
                    placeholder="Email"
                    {...register("email")}
                />
                {errors.email && <span className={styles.errorMessage}>{errors.email?.message}</span>}
                <input
                    type="text"
                    placeholder="Phone"
                    {...register("phone")}
                />
                {errors.phone && <span className={styles.errorMessage}>{errors.phone?.message}</span>}
                <input
                    type="text"
                    placeholder="Service"
                    {...register("service")}
                />
                {errors.service && <span className={styles.errorMessage}>{errors.service?.message}</span>}

                <div className={styles.datepicker}>
                    <DatePicker
                        selected={selectedDate}
                        placeholderText='Date & Time'
                        dateFormat="MMMM d, yyyy h:mm aa"
                        isClearable
                        showTimeSelect
                        minDate={new Date()}
                        maxDate={new Date(new Date().getFullYear() + 1, 11, 31)}
                        minTime={minTime}
                        maxTime={maxTime}
                        filterDate={date => date.getDay() !== 1}
                        excludeTimes={bookedTimes.map(time =>  new Date(time))}
                        onChange={(date) => setSelectedDate(date)} />
                </div>

                <input 
                    type='submit'                
                />
            </form>
            
    
        </section>
    );
};