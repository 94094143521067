import { React, useState } from 'react'
import styles from './Services.module.css';
import servicesList from "./Services-data.json";
import threadingImg from "../../assets/services/Threading.jpeg";
import haircutImg from "../../assets/services/Haircut.jpeg";
import waxingImg from "../../assets/services/Waxing.jpeg";
import facialImg from "../../assets/services/Facial.jpeg";

const images = [threadingImg, waxingImg, haircutImg, facialImg];


export const Services = () => {
    let currentIndex = 0;
    const [isHovered, setIsHovered] = useState(false);
  return (
    <section className={styles.container} id='services'>
        <h1 className={styles.title}>Services</h1>
        <div className={styles.content}> 
            <ul className={styles.services}>
                
                {servicesList.map(service =>(
                    <li style={{ 
                        position: "relative",
                        backgroundImage: `linear-gradient(to bottom,  rgb(0 0 0 / 15%), rgb(0 0 0 / 15%)),url(${images[currentIndex++]})`, 
                        backgroundSize: "cover",
                        fontFamily: "var(--font-family)",
                        }}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                        key = {service.id} className={styles.serviceItem}>
                     {/*<img src={`${images[currentIndex++]}`} alt={` ${currentIndex + 1}`} /> */}
                     
                    <div>
                        <h2>{service.service}</h2>
                        {/*<p>${service.price}</p>*/}
                    </div>
                </li>
                ))}
            </ul>
        </div>
        
        <a href="#appointment">Book Now</a>
    </section>
  )
}
