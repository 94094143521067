import React from 'react'
import styles from "./Welcome.module.css";

export const Welcome = () => {
  return (
    <section className={styles.container}>
        <div className={styles.content}>
            {/*<img src={require('../../assets/store1.jpg')} alt="store" />*/}
            <h1>
                Welcome to Bansi Salon & Spa!
            </h1>
            <p>
                Treat yourself to a journey of beauty and wellness.
            </p>
            <a href="#contact">Contact Us</a>
        </div>
    </section>
  )
}
